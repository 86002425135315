html,
body {
  height: 100%;
}

.space{
  margin-top: 10px;
}

.icon-pointer {
  cursor: pointer;
}

.logo{
  height: 33px !important;
}